export const MESSAGES = {
  REQUIRED: 'This field is required.',
  IMAGE_FORMAT: 'Incorrect file format. Please upload a jpg, png or a jpeg file.',
  IMAGE_SIZE: 'Company logo size should be of maximum 10 mb.',
  COMPANY_LOGO_SIZE: 'Company logo size should be of maximum 4 mb.',
  EMAIL_INVAILD: 'Invalid email address.',
  PASSWORD_INVALID: 'Invalid password.',
  INVALID_NUMBER: 'Please enter a valid number.',
  INVALID_TURBINE_HEIGHT: 'Invalid turbine height.',
  USER_EXIST: 'This email is already registered with us.',
  UNAPPROVED_USER: 'Your account is under review, we will send you an email confirmation once your account is approved.',
  SIGNUP_SUCCESS: 'Dsider admin will review your account details & send you an email confirmation once your account is approved.',
  RESEND_CODE: 'Verification code sent to your email.',
  INVALID_CODE: 'Incorrect code. Please try again.',
  PASSWORD_HINT: 'Password must contain at least eight characters with one uppercase, one lowercase, one numeric, and one special character.',
  ACCOUNT_NOT_APPROVED: 'Your account has not been approved yet.',
  WRONG_CREDS: 'Incorrect email or password.',
  USER_DEACTIVATED: 'This user has been deactivated. Please contact Dsider Admin.',
  PROFILE_UPDATED: 'Profile updated successfully!',
  PROFILE_PICTURE_UPDATED: 'Profile picture updated successfully!',
  PROJECT_CREATED: 'Project created successfully!',
  PROJECT_INVALID_NAME: 'A project with that name already exists. Please choose a different name.',
  PROJECT_UPDATED: 'Project updated successfully!',
  PROJECT_DELETED: 'Project removed successfully!',
  SESSION_EXPIRED: 'Your session has been expired.',
  INTERNAL_SERVER_ERROR: 'Internal Server Error. Please try again.',
  SCENARIO_IS_DELETED: 'You do not have access to this scenario.',
  SCENARIO_CREATED: 'Scenario created successfully!',
  SCENARIO_DELETED: 'Scenario deleted successfully!',
  SCENARIO_DUPLICATED: 'Scenario duplicated successfully!',
  SHARED_SCENARIO_DUPLICATED: 'Shared Scenario Dupliated! A Project is created with the duplicated scenario.',
  SCENARIO_UPDATED: 'Scenario updated successfully!',
  WORKBENCH_SAVED: 'Scenario saved successfully!',
  WORKBENCH_CREATED: 'Scenario created successfully!',
  SIMULATION_STARTED: 'Simulation Started...',
  SIMULATION_RUNNING: 'Simulation is running',
  SIMULATE_NOW: 'The scenario is ready for simulation. Go to workbench & simulate now!',
  COMPONENT_MISMATCH: 'Components Mismatch!',
  EXCEL_DATA_MISMATCH: 'Imported Excel file does not match. Please Import correct excel file.',
  PROPERTIES_SAVED: 'Component properties saved successfully!',
  NO_PROJECT_SELECTED: 'Please select a project first!',
  PROJECT_DISCARD_MESSAGE: 'If you discard the project now, all data will be removed.',
  EDIT_PROJECT_DISCARD_MESSAGE: 'If you discard now, your updates will not be saved.',
  SCENARIO_DISCARD_MESSAGE: 'If you discard the Scenarion now, all data will be removed.',
  SIGNUP_DISCARD_MESSAGE: 'If you discard now, your progress will be lost.',
  SOMETHING_WENT_WRONG: 'Something went wrong !',
  GENERIC_ERROR_MESSAGE: 'Some parameters value is not valid. Please update the parameters and simulate again.',
  FORGOT_SUCCESS: 'Password changed successfully! Please log in to continue.',
  PROJECT_DELETE_ALERT: 'If you delete the project now, all data will be removed permanently.',
  NO_RECORD_FOUND: 'No results found!',
  NO_PROJECT_FOUND: 'You have no projects here!',
  NO_SCENARIO_FOUND: 'You have no scenarios here!',
  NO_LAYERS_YET: 'This workbench has no Layered components.',
  NO_SIMULATION: 'No simulations yet',
  SIGNOUT_MESSAGE: 'You will be signed out of the Dsider platform.',
  UNSAVED_CHANGES: 'There are unsaved changes in the workbench. Please save the scenario first.',
  WORKBENCH_CONFIRM_MESSAGE: 'There are unsaved changes in the workbench. Do you want to save your changes or discard them?',
  DELETE_SCENARIO_CONFIRM_MESSAGE: 'If you delete the scenario now, all data will be deleted permanently.',
  UNSHARE_SCENARIO_CONFIRM_MESSAGE: 'Once you un-share this scenario, you will no longer be able to access it.',
  UNSHARE_SUCCESSFULLY: 'Scenario un-shared successfully!',
  CLEAR_BOARD_CONFIRM_MESSAGE: 'If you clear the board now, all data of the current layer and its dependent layers will be deleted.',
  CLEAR_LINK_CONFIRM_MESSAGE: 'The linking among all the components on the current workbench layer will be removed.',
  CHANGE_PASS_SUCCESS: 'Password updated successfully!',
  USE_EXCEL_INFO: 'To add data for more years, please use Import Excel feature',
  LOCATION_REQUIRED_FIELD: 'Location is required field.',
  JSON_INVALID: 'Please select a .json file',
  JSON_OBJECT_PERMISSION_INVALID: 'The provided JSON contains some objects for which access permissions are not granted. Please import different JSON file.',
  SOMETHING_WRONG: 'Something went wrong, please try again later',
  NO_SIMULATION_DATA: 'No simulation data available',
  COMPARE_NO_SIM_DATA: 'One or more of the chosen scenarios are not simulated',
  EXCEL_INVALID: 'Invalid file type. Please select a valid Excel file.',
  OTO_REQ: 'OTP is required.',
  NEW_PASSWORD_REQ: 'New password is required.',
  CURRENT_PASS_REQ: 'Current password is required.',
  IMPORT_SUCCESS: 'JSON data imported successfully!',
  EXPORT_SUCCESS: 'JSON data exported successfully!',
  EXCEL_IMPORT_SUCCESS: 'Excel data imported successfully!',
  EXCEL_EXPORT_SUCCESS: 'Excel data exported successfully!',
  EXCEL_VALUE_NOT_COMPATIBLE: 'This field value is not compatible. Please update the values type.',
  EXCEL_VALUES_NOT_COMPATIBLE: 'These fields values are not compatible. Please update the values type.',
  NO_EXPORT_DATA: 'Workbench is empty! Please create a workflow to export.',
  COMPANY_NAME_REQ: 'Company name is required.',
  COMPANY_DESC_REQ: 'Company description is required.',
  EMAIL_REQUIRED: 'Email address is required',
  PASS_REQUIRED: 'Password is required',
  PROJECT_NAME_REQ: 'Project name is required.',
  PROJECT_DESC_REQ: 'Project description is required.',
  PROJECT_TYPE_REQ: 'Project type is required.',
  SCENARIO_NAME_REQ: 'Scenario name is required.',
  SCENARIO_DESC_REQ: 'Scenario description is required.',
  LOCATION_SETTINGS: 'Location Settings updated successfully!',
  SUBSIDIES_SETTINGS: 'Subsidies/year is required',
  SETTINGS_UDPATED: 'Settings updated successfully!',
  INCORRECT_NUMBER_FORMAT: 'Only numbers are allowed',
  RANGE_NUMBERS: 'Please enter valid range from 1 - 100',
  DISCOUNT_RATE_REQURED: 'Discount Rate is required.',
  CC_TAX_REQUIRED: 'CC Tax is required.',
  TERMINAL_VALUE_REQUIRED: 'Terminal value is required.',
  YEARS_REQUIRED: 'Number of years is required.',
  START_YEAR: 'Start year is required.',
  UNSHARE_USER_MSG: 'If you remove the user, the shared scenario invite will be revoked.',
  UNSHARE_SUCCESS: 'Invite successfully revoked for the user!',
  SIMULATION_SAVED: 'Simulation data saved!',
  DISCOUNT_VALIDATION: 'Discount rate must be between 0 to 1',
  PERCENT_VALIDATION: 'Only 1-100 is allowed with two decimal places',
  SIMULATION_IS_COMPLETED: 'Simulation completed successfully!',
  SCENARIO_CANT_SHARE: 'Selected scenario cannot be shared, as it doesn\'t contain any simulation data.',
  SCENARIO_CHAT_MESSAGE: 'If you clear the board now, all messages will be removed.',
  NO_SIMULATION_DATA_FOUND: 'No simulation data found.',
  EV_SIMULATION_CONFIRM_MESSAGE: 'There are unsaved changes in the simulation. Do you want to save your changes or discard them?',
  EV_SIMULATION_SAVED: 'Simulation saved successfully!',
  EV_COPY_SIMULATION_CREATED: 'A copy of the scenario created!',
  EV_SIMULATION_SAVED_ERROR: 'There was an error saving this simulation.',
  DECIMAL_VALIDATION: 'Maximum 2 decimal places numbers are allowed',
  RADIUS_BEYOND_2_MILES: 'The data is segregated within a 2-mile radius, so it displays information limited to that range.',
  SHARED_SUCESS: 'Scenario shared successfully!',
  CANNOT_SHARE_YOURSELF: 'You can not share the scenario to yourself, please enter a different email id.',
  ALREADY_SHARED: 'The scenario is already shared with the user. Please enter a different email address.',
  START_APPROVAL: `Initiating the approval process will lock the scenario, and no further <span>editing</span> will be possible.
   All users will have <span>view-only</span> access.`,
  LAYERS_LIMIT_EXCEEDED: 'You have reached the maximum number of layers',
  REQUEST_SUCCESS: 'Approval request sent successfully!',
  SCENARIO_UPDATED_SUCCESS: 'Scenario approval status has been updated successfully!',
  INVALID_OLD_PASSWORD: 'Current password is incorrect.',
  INVALID_NEW_PASSWORD: 'Current password and New password is same. Please use different password.',
  APPROVER_UPDATED: 'Approver Updated!',
  SELF_APPROVED: 'Scenario has been self-approved successfully!',
  RE_SUBMIT_REQUEST: 'Scenario has been resubmitted for approval.',
  NO_NEW_APPROVER: 'No new approver is added',
  ADD_TO_BASELINE: 'Adding this scenario to the Baseline will permanently lock it, preventing any further changes.',
  ADD_TO_BASELINE_SUCCESS: 'Scenario added to Baseline successfully!',
  NO_REQUEST_YET: 'No request at the moment!',
  USER_ADDED_SUCCESS: 'User added successfully!',
  USER_ADD_ERROR: 'This email address has already been used. Please use a different email address.',
  COMPANY_ADDED_SUCCESS: 'Company added successfully!',
  OBJECT_ADDED_SUCCESS: 'Object added successfully!',
  OBJECT_UPDATED_SUCCESS: 'Object updated successfully!',
  USER_UPDATED_SUCCESS: 'User Details updated successfully!',
  DEACTIVATE_USER_STATUS_MESSAGE: 'Are you sure you want to deactivate this user? The user won’t be able to access the platform any further.',
  ACTIVATE_USER_STATUS_MESSAGE: 'Are you sure you want to activate this user? The user will regain access to the platform.',
  DELETE_USER_MESSAGE: 'Are you sure you want to delete the user permanently?',
  DELETE_OBJECT_MESSAGE: 'Are you sure you want to delete the object permanently?',
  REQUIRED_FIRST_NAME: 'First name is required.',
  REQUIRED_LAST_NAME: 'Last name is required.',
  REQUIRED_EMAIL: 'Email is required.',
  INVALID_EMAIL: 'Invalid email format.',
  REQUIRED_ROLE: 'User role is required',
  REQUIRED_COMPANY: 'Company is required',
  USER_DELETED_SUCCESS: 'User deleted successfully!',
  OBJECT_DELETED_SUCCESS: 'Object deleted successfully!',
  USER_NOT_SIGNED_UP_YET: 'The scenario was shared successfully! The user you shared with has not yet signed up for the app.',
  ALPHABET_REQUIRED: 'At-least one alphabet is required.',
  COMPANY_NAME_REQUIRED: 'Company name is required.',
  ACCESS_PROJECT_TYPE_REQUIRED: 'Access to project type is required.',
  ACCESS_TO_OBJECTS_REQUIRED: 'Access to objects is required.',
  DESCRIPTION_REQUIRED: 'Description is required.',
  PERMISSION_ROLE_CONSUMER_REQUIRED: 'At least one permission must be selected for consumer role',
  PERMISSION_ROLE_COLLABORATOR_REQUIRED: 'At least one permission must be selected for collaborator role',
  PERMISSION_ROLE_SUPERUSER_REQUIRED: 'At least one permission must be selected for superuser role',
  COMPANY_DELETED_SUCCESS: 'Company deleted successfully!',
  DEACTIVATE_COMPANY_STATUS_MESSAGE: 'Are you sure you want to deactivate this company? The company won’t be able to access the platform any further.',
  ACTIVATE_COMPANY_STATUS_MESSAGE: 'Are you sure you want to activate this company? The company will regain access to the platform.',
  DELETE_COMPANY_MESSAGE: 'Are you sure you want to delete the company permanently?',
  ROLE_ERROR: 'Please select at least one role permission',
  COMPANY_UPDATE_SUCCESS: 'Company details updated successfully.',
  USER_ACTIVATE_FAILED: 'This user can`t be activated as the associated company is inactive.',
  COMPANY_PERMISSION_UPDATE_SUCCESS: 'User permission updated successfully.',
  DISCARD_COMPANY_PERMISSION_MESSAGE: 'If you discard now, your progress will be lost.',
  OBJECT_ACCESS_UPDATE_SUCCESS: 'Object access updated successfully.',
  OBJECT_ACCESS_UPDATE_ERROR: 'There was an error updating the object access.',
  OBJECT_NAME_EXISTS: 'Object name already exists',
  OBJECT_NAME_AVAILABLE: 'Object name is available',
  PARAMETER_UPDATED_SUCCESS: 'Parameter updated successfully.',
  PARAMETER_ADDED_SUCCESS: 'New Parameter added successfully.',
  PARAMETER_DELETED_SUCCESS: 'Parameter removed successfully.',
  PARAMETER_ALREADY_EXISTS: 'Parameter already exists.',
  MODIFY_QUE_RESPONSE: 'Modifying the response of this question will reset further questions as well.',
  COMPONENT_NOT_EXIST: 'Component does not exist.',
  MONTE_CARLO_SIM_STARTED: 'Monte carlo simulation has been started. It will take a while to compute the results. Please wait, you will get an email once it is completed.',
  OUTPUT_LIMIT: 'You can only select two outputs to track as of now.',
  SIM_RUNNING: 'Monte carlo simulation might be under progress. Please wait for some time or Run the simulation again.',
  PRE_LOGOUT_MSG: 'Attention: You\'ve been inactive for a while. If you continue to remain inactive, you\'ll be logged out. Do you wish to stay logged in?'
};

export const AWS_CONGNITO_ERRORS = {
  INVALID_OTP: 'Invalid verification code provided, please try again.',
  ATTEMPT_EXCEEDED: 'Attempt limit exceeded, please try after some time.'
};
