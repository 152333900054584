import React, { useRef } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { CARBON_SETTINGS_SCHEMA, formatNumberWithCommas, keyPressHandle, parseFormattedNumber } from '../../utils';
import { ICarbonSettings } from '../../constants';
import { FormikProps, Formik, ErrorMessage } from 'formik';

interface IFormikProps {
  handleForm: (values: any) => void
  formData: any
  fieldChange: any
  loading: boolean
  hideModal: () => void
}

const CarbonSettings = (props: IFormikProps) => {
  const formikRef = useRef<FormikProps<ICarbonSettings> | null>(null);

  const initialValues: ICarbonSettings = {
    subsidies: props.formData?.subsidies || ''
  };

  return (
    <>
      <div className="tab-main-container">
        <div className="tab-main-wrapper">
          <div className="financial-tab carbon-tab">
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={CARBON_SETTINGS_SCHEMA}
              onSubmit={props.handleForm}
            >
              {({ values, errors, handleSubmit, setFieldValue, handleChange, handleBlur, touched, setFieldError, ...rest }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="setting-form-content object-form-wrap">
                    <div className="object-left-col row">
                      <div className="col-md-6">
                        <Form.Group
                          controlId="formSubsidies"
                          className="postion_relative"
                        >
                          <Form.Label>Subsidies/Year<sup>*</sup></Form.Label>
                          <Form.Control
                            type='text'
                            value={formatNumberWithCommas(values?.subsidies)}
                            name='subsidies'
                            data-testid="subsidies"
                            required={true}
                            onChange={event => {
                              event.target.value = parseFormattedNumber(event.target.value);
                              handleChange(event);
                            }}
                            onKeyDown={keyPressHandle}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage className="error-msg" name="subsidies" component='span' />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                  <div className="setting-btn-wrap">
                    <Button className="btn-no-outline" onClick={props.hideModal}>
                      Cancel
                    </Button>
                    <Button className="primary" type="submit">
                      {props.loading ? <Spinner /> : 'Save Changes'}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default CarbonSettings;
