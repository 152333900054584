import React, { useState } from 'react';
import { MESSAGES, NUMBER, POWER_SOURCES, POWER_SOURCES_EDGES, SCENARIO_TYPES, WIZARD_SCENARIO_VIEWPORT } from '../../constants';
import { SellToGridQuestion } from './WizardQuestionType';
import { IAnswers, IWizardQuestionRes } from './wizardInterface';
import { Edge, Node } from 'reactflow';
import { Button } from 'react-bootstrap';
import { getWorkbenchURL, gotoWizardLogo, redirectArrow } from '../../utils';
import { useSaveWorkbenchDataMutation } from '../../redux/services/workbenchApis';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { startFullLoading, stopFullLoading } from '../../redux/SpinnerSlice';
import { toast } from 'react-toastify';
import { LocationQuestion } from '../../components/wizard-questions/LocationQuestion';
import { PowerSourcesQuestion } from '../../components/wizard-questions/PowerSourcesQuestion';
import { SourcesCapacityQuestion } from '../../components/wizard-questions/SourcesCapacityQuestion';
import { DemandSupplyQuestion } from '../../components/wizard-questions/DemandSupplyQuestion';
import UserAvatar from '../../components/approval-workflow/UserAvatar';

interface PowerQuestionListProps {
  wizardQuestions: IWizardQuestionRes[]
  currentQuesttion: number
  lastQuestion: number
  scenarioId: string
  scenarioType: string
  scenarioName: string
  submitQuestion: (nextQuesttionIndex: number) => void
}


function PowerQuestionList(props: PowerQuestionListProps) {
  const { wizardQuestions, currentQuesttion, lastQuestion, scenarioId, scenarioName, scenarioType, submitQuestion } = props;

  const powerDefaultAnswers: IAnswers = {
    location: '',
    powerSources: [],
    hydrogenSources: [],
    powerSourceCapacity: [],
    hydrogenSourceCapacity: [],
    usePowerGrid: null,
    sellToGrid: null,
    toGridCapacity: NUMBER.N0,
    sellFromGrid: null,
    fromGridCapacity: NUMBER.N0,
    supplyDemand: scenarioType === SCENARIO_TYPES.power ? POWER_SOURCES.ElectricPowerDemand.data?.Demand : POWER_SOURCES.HydrogenDemand.data?.hydrogen_schedule
  };

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { firstName, lastName, image_url } = useAppSelector(state => state.auth);

  const [saveWorkbenchData] = useSaveWorkbenchDataMutation();

  const [answersList, setAnswersList] = useState<IAnswers>(powerDefaultAnswers);
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);

  const updateLocation = (nodes: Node[]) => {
    return nodes.map((node) => {
      return {
        ...node,
        data: {
          ...node.data,
          location: answersList.location,
          abatement_location: answersList.location
        }
      };
    });
  };

  const getScenarioNodes = () => {
    const selectedNodes: Node[] = [];
    const nodesEdges: Edge[] = [];

    // push selected power sources with their updated capacity values...
    answersList.powerSourceCapacity.map((ps) => {
      const node = POWER_SOURCES[ps.component];
      const edge = POWER_SOURCES_EDGES[ps.component];
      node.data[ps.CapacityProperty] = ps.capacity;
      if (ps.component === 'Grid') {
        node.data.P_max_in = answersList.toGridCapacity ?? node.data.P_max_in;
      }
      selectedNodes.push(node);
      nodesEdges.push(...edge);
    });
    // push selected hydrogen sources with their updated capacity values...
    answersList.hydrogenSourceCapacity.map((hs) => {
      const node = POWER_SOURCES[hs.component];
      const edge = POWER_SOURCES_EDGES[hs.component];
      node.data[hs.CapacityProperty] = hs.capacity;
      selectedNodes.push(node);
      nodesEdges.push(...edge);
    });

    // push default nodes and edges which is must to have in workbench...
    const defaultNodes = [];
    const defaultEdges = [];
    if (scenarioType === SCENARIO_TYPES.power) {
      const powerDemand = {
        ...POWER_SOURCES.ElectricPowerDemand,
        data: {
          ...POWER_SOURCES.ElectricPowerDemand.data,
          Demand: answersList.supplyDemand
        }
      };
      defaultNodes.push(powerDemand, POWER_SOURCES.ElectricityLB);
      defaultEdges.push(...POWER_SOURCES_EDGES.ElectricPowerDemand);
    } else {
      const hydrogenDemand = {
        ...POWER_SOURCES.HydrogenDemand,
        data: {
          ...POWER_SOURCES.HydrogenDemand.data,
          hydrogen_schedule: answersList.supplyDemand
        }
      };
      defaultNodes.push(POWER_SOURCES.ElectricityLB, POWER_SOURCES.LB, POWER_SOURCES.electrolyzer, hydrogenDemand);
      defaultEdges.push(...POWER_SOURCES_EDGES.electrolyzer, ...POWER_SOURCES_EDGES.HydrogenDemand);
    }

    const updatedNodes = [...selectedNodes, ...defaultNodes];
    const updatedEdges = [...nodesEdges, ...defaultEdges];

    return { updatedNodes, updatedEdges };
  };

  const createWizardScenario = () => {
    const { updatedNodes, updatedEdges } = getScenarioNodes();
    const scenarioNodes = updateLocation(updatedNodes);

    const payload = {
      theme: '',
      id: scenarioId,
      name: scenarioName,
      exportJSON: {
        nodes: scenarioNodes,
        edges: updatedEdges,
        viewPort: WIZARD_SCENARIO_VIEWPORT
      }
    };

    dispatch(startFullLoading());
    saveWorkbenchData(payload).then(res => {
      if ('data' in res) {
        toast.success(MESSAGES.WORKBENCH_CREATED);
        dispatch(stopFullLoading());
        navigate(getWorkbenchURL(scenarioId, scenarioType), {
          state: {
            project: location?.state?.project,
            navigateFrom: location?.state?.navigateFrom
          }
        });
      } else {
        dispatch(stopFullLoading());
      }
    });
  };

  const showCapacitySources = (que: IWizardQuestionRes) => {
    return que.type === 'text' && que.mapping !== 'Demand' && que.mapping !== 'demand schedule';
  };

  return (
    <>
      {
        wizardQuestions?.map((que, index) => {
          const questionIndex = index + NUMBER.N1;
          return (
            currentQuesttion >= questionIndex && <div key={`${que.type}-${index}`}>
              {
                que.type === 'location' && <LocationQuestion que={que} index={questionIndex}
                  answersList={answersList} setAnswersList={setAnswersList} submitQuestion={submitQuestion}
                  edges={edges} setEdges={setEdges} nodes={nodes} setNodes={setNodes} />
              }
              {
                que.type === 'checkbox' && <PowerSourcesQuestion que={que} index={questionIndex}
                  answersList={answersList} setAnswersList={setAnswersList} submitQuestion={submitQuestion}
                  edges={edges} setEdges={setEdges} nodes={nodes} setNodes={setNodes} />
              }
              {
                showCapacitySources(que) && <SourcesCapacityQuestion que={que} index={questionIndex}
                  answersList={answersList} setAnswersList={setAnswersList} submitQuestion={submitQuestion}
                  edges={edges} setEdges={setEdges} nodes={nodes} setNodes={setNodes} />
              }
              {/* Sell To Grid and Sell From Grid */}
              {
                que.type === 'radio-button' && <SellToGridQuestion que={que} index={questionIndex}
                  answersList={answersList} setAnswersList={setAnswersList} submitQuestion={submitQuestion}
                  edges={edges} setEdges={setEdges} nodes={nodes} setNodes={setNodes} gridType={que.mapping === 'to grid' ? 'sellToGrid' : 'sellFromGrid'} />
              }
              {/* Power Demand consumption */}
              {
                (que.mapping === 'Demand' || que.mapping === 'demand schedule') && <DemandSupplyQuestion que={que} index={questionIndex}
                  answersList={answersList} setAnswersList={setAnswersList} submitQuestion={submitQuestion}
                  edges={edges} setEdges={setEdges} nodes={nodes} setNodes={setNodes} />
              }
            </div>
          );
        })
      }
      {wizardQuestions.length > NUMBER.N0 && <div className="dropdown-wrapper question-list">
        <UserAvatar firstname={firstName} lastname={lastName} imageUrl={image_url} classname={currentQuesttion === NUMBER.N1 ? 'first-question' : ''} />
      </div>}
      {/* Final scenario preview */}
      {currentQuesttion > lastQuestion && <div className="wizard-ques-main">
        <div className="wizard-ques-inner">
          <h2 className='wizard-ques-head'>Based on your inputs, we have created a scenario for you.</h2>
          <div className="message my-message">
            <div className="ai-img-msg">
              <img src={gotoWizardLogo} alt="logo" className="ai-map-img" />

              <Button className="simulation-summary-btn btn btn-primary" onClick={createWizardScenario}>See Workbench
                <img src={redirectArrow} alt="logo" className="redirectArrow-chat" />
              </Button>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
}

export default PowerQuestionList;
