import React, { FC, useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsBoost from 'highcharts/modules/boost';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsStock from 'highcharts/modules/stock';
import { GEO_THERMAL, NUMBER } from '../../constants';
import { GRAPH_COLORS_ARRAY, SCENARIO_GRAPH_LIST, globalObject, rangeSelectorObj } from '../../constants/graph.constant';
import ComparePowerLegends from './ComparePowerLegends';
import { Button, Spinner, Tab, Tabs } from 'react-bootstrap';
import FinancialTab from './FinancialTab';
import { dataGroupingObj, getGeoThermalGraphOptionData, handleDataGrouping, tooltipObj } from '../../components/shared/Graph/GraphUtils';
import { getGeoThermalGraphSeries } from '../../utils';
import MapView from './MapView';
import ObservationModal from './addObservationModal';
import PdfDocument from '../../components/shared/PdfGenerator';

HighchartsBoost(Highcharts);
HighchartsExporting(Highcharts);
HighchartsStock(Highcharts);

interface Props {
  selectedScenarioList: any[]
  comparedScenarios: any[]
  financialData: any
  mappedKeys: any
  subProjectType: string
  clearAll: () => void
}

const CompareGeothermalScenario: FC<Props> = ({ selectedScenarioList, comparedScenarios, financialData, mappedKeys, subProjectType, clearAll }) => {
  const [graphOptions, setGraphOptions] = useState<any>();
  const [graphLoading, setGraphLoading] = useState(true);
  const [legend, setLegends] = useState<any>([]);
  const [activeKey, setActiveKey] = useState<string | null>('');
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const [imageDataUrl, setImageDataUrl] = useState<any>();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [observationValue, setObservationValue] = useState<string>('');
  const [contextValue, setContextValue] = useState<string>('');
  const [renderPdf, setRenderPdf] = useState<boolean>(false);

  const commonMarker = {
    symbol: 'circle', // Customize the symbol for the legend
    radius: NUMBER.N6// Customize the radius of the legend marker
  };

  const getSeries = () => Object.keys(comparedScenarios[NUMBER.N0]).filter(key => key !== 'TimeUTC').map((key, index) => {
    return {
      name: key,
      data: comparedScenarios.map((a: any) => [new Date(a.TimeUTC + 'Z').getTime(), a[key]]),
      dataGrouping: dataGroupingObj,
      marker: commonMarker,
      color: GRAPH_COLORS_ARRAY[index]
    };
  });


  useEffect(() => {
    if (comparedScenarios?.length) {
      setGraphOptions({
        title: { text: '' },
        global: globalObject,
        tooltip: tooltipObj,
        xAxis: {
          type: 'datetime',
          crosshair: true,
          labels: {
            formatter: (value: any) => {
              return Highcharts.dateFormat('%b %d, %Y', value.value); // Format the label as "Jan 23, 2023"
            }
          }
        },
        yAxis: {
          title: { text: 'Power (kWh)' },
          gridLineWidth: NUMBER.N1,
          gridLineColor: '#D2D2D2'
        },
        legend: {
          enabled: false
        },
        rangeSelector: rangeSelectorObj(false),
        series: getSeries(),
        navigator: {
          enabled: true
        }
      });
      setGraphLoading(false);
    }
  }, [comparedScenarios]);

  const handleChartRender = (chart: any) => {
    // Access the legend items as an array
    const legendItems = chart.legend.allItems;

    const items = legendItems.map((item: any) => {
      return {
        label: item.name,
        color: item.color
      };
    });
    setLegends(items);

    const series = chart?.series[0];
    if (chart?.rangeSelector?.buttons?.length) {
      handleDataGrouping(chart, series);
      // Add event listener to the range selector buttons
      chart.rangeSelector.buttons.forEach((button: any) => {
        button.element.addEventListener('click', () => {
          handleDataGrouping(chart, series);
        });
      });
    }
  };

  useEffect(() => {
    if (subProjectType === GEO_THERMAL && financialData?.length) {
      const graph = SCENARIO_GRAPH_LIST[subProjectType]?.[NUMBER.N0];
      const compareSeriesData: any[] = [];
      let compareYears: any[] = [];
      financialData.map((simData: any, index: number) => {
        let seriesData: any = {};
        if (simData?.[graph.graphName]) {
          seriesData = getGeoThermalGraphSeries(simData?.[graph.graphName], graph.secondaryYAxis as string, selectedScenarioList?.[index]?.label);
          compareSeriesData.push(...seriesData.series);
          compareYears = seriesData.years;
        }
      });
      const graphSeriesData = { series: compareSeriesData, years: compareYears };
      const seriesOptionData = getGeoThermalGraphOptionData(graphSeriesData, graph, false);
      setGraphOptions(seriesOptionData);
      setGraphLoading(false);
    }
  }, [subProjectType, financialData]);

  const handleSelect = (key: string | null) => {
    setActiveKey(key);
  };

  const renderPdfImage = (chart: any) => {
    setTimeout(() => {
      const chartSvg = chart.container.getElementsByTagName('svg')[0].outerHTML;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const DOMURL = window.URL || window.webkitURL || window;
      const img = new Image();
      const svgBlob = new Blob([chartSvg], { type: 'image/svg+xml;charset=utf-8' });
      const url = DOMURL.createObjectURL(svgBlob);

      img.onload = function () {
        if (ctx) {
          canvas.width = chart.container.offsetWidth;
          canvas.height = chart.container.offsetHeight;
          ctx.drawImage(img, 0, 0);
          DOMURL.revokeObjectURL(url);
          const chartDataUrl = canvas.toDataURL('image/png');
          setImageDataUrl(chartDataUrl);
        }
      };
      img.src = url;
      setRenderPdf(true);
    }, NUMBER.N1000);
  };

  const printView = () => {
    const chart = chartRef.current?.chart;
    if (chart) {
      renderPdfImage(chart);
    }
  };

  const openPdfViewer = (observationVal: string, contextVal: string) => {
    setIsSubmit(true);
    setRenderPdf(false);
    setObservationValue(observationVal);
    setContextValue(contextVal);
  };

  return (
    <div className="dsider-tab-wrapper compare-modal-tab">
      <div className="">
        {financialData?.length && selectedScenarioList?.length &&
        <>
          <div className="print-tb-wrap">
            {/* {activeKey === 'graphView' && <Button className="btn-no-outline print-btn" onClick={() => printView()}>Print Summary</Button>} */}
            <Button className="btn-no-outline" onClick={() => clearAll()}>Clear All</Button>
          </div>
          <Tabs defaultActiveKey={'Tabular View'} id="uncontrolled-tab-example" onSelect={handleSelect}>
            <Tab eventKey="Tabular View" title="Tabular View">
              <FinancialTab financialData={financialData} projectType={subProjectType} selectedScenarioList={selectedScenarioList} />
            </Tab>
            <Tab eventKey="graphView" title="Graph View">
              <div className="col-md-12">
                <div className={`table-wrapper power-table-wrap ${subProjectType === GEO_THERMAL ? 'geoThermal-table-wrap' : ''}`}>
                  <div className="simulation-chart-wrapper" >
                    {graphLoading
                      ? <div className='map-data-loader'><Spinner /></div>
                      : <>
                        <div className="chart-left">
                          <h2 className="chart-title">Compare Plots</h2>
                          <div className='power-graph-wrap'>
                            <HighchartsReact ref={chartRef} highcharts={Highcharts} options={graphOptions} callback={(chart: any) => {
                              handleChartRender(chart);
                            }} />
                          </div>
                        </div>
                        <ComparePowerLegends
                          selectedScenarioList={selectedScenarioList}
                          mappedKeys={mappedKeys?.length ? mappedKeys : legend}
                        />
                      </>}
                  </div>
                </div>
              </div>
            </Tab>
            {/* <Tab eventKey="MapView" title="Map View">
              <MapView financialData={financialData} projectType={subProjectType} selectedScenarioList={selectedScenarioList} />
            </Tab> */}
          </Tabs>
        </>}

      </div>
      {renderPdf && <ObservationModal show={renderPdf} handleClose={() => setRenderPdf(false)}
        isSubmit={(value: string, value2: string) => openPdfViewer(value, value2)} />}
      {isSubmit && <PdfDocument chartImage={imageDataUrl}
        onClose={() => setIsSubmit(false)} value={observationValue} contextVal={contextValue}
        show={isSubmit} financialData={financialData} />}
    </div>
  );
};

export default CompareGeothermalScenario;
