import React, { FC } from 'react';

interface Props {
  selectedScenarioList: any[]
  mappedKeys: any
}

const ComparePowerLegends: FC<Props> = ({ selectedScenarioList, mappedKeys }) => {
  return (
    <div className="chart-right">
      <div className="chart-right-inner">
        <div className="slider-wrap">
          <div className="simulation-form-wrap power-tab-right">
            {selectedScenarioList.map((scenerio: any, i: number) => <><p className="slider-head">{scenerio.label}</p>
              {mappedKeys.map((key: any, index: number) => {
                if (key.scenerioName === scenerio.label || key.label.includes(scenerio.label)) {
                  return <div className="scenario-list" key={key.label}>
                    <div className="color-box" style={{ backgroundColor: key.color }}></div>
                    <span className='color-box-des'>{key.label.includes(scenerio.label) ? key.label.replace(` | ${scenerio.label}`, '') : key.label}</span>
                  </div>;
                } else {
                  return <></>;
                }
              })}

            </>)}

            {/* <div className="simulate-btn-wrap">
              <Button className="primary">
                <img src={exportIcon} alt="logo img" className='export-logo' />
                            Export</Button>
            </div>
            <Link to={''} className='data-table-link' >See Data Table</Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparePowerLegends;
